import Vue from 'vue/dist/vue.esm';

function vueRender({
	loading
}) {
	return new Vue({
		template: `
      <div id="subapp-container">
        <div id="subapp-viewport"></div>
      </div>
    `,
		el: '#subapp-container',
		data() {
			return {
				loading,
			};
		},
	});
}

let app = null;

export default function render({
	loading
}) {
	if (!app) {
		app = vueRender({
			loading
		});
	} else {
		app.loading = loading;
	}
}
