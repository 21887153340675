/**
 * 配置文件
 */

// 开发环境
export function getConfig(loader) {
    // 开发环
    const devConfig = [
		/*
        // 猎风OA(已经整合到flowapp-ui)
        {
            // 名字可以随意起，但是在子系统的vitePlugins 组成的时候就要用这里定义的名字
            name: 'cloud-oa',
            entry: '//localhost:3001',
            container: '#subapp-viewport',
            loader,
            activeRule: '/oa',
        },*/
        // 超管后台
        {
            name: 'cloud-super-admin',
            entry: '//localhost:3002',
            container: '#subapp-viewport',
            loader,
            activeRule: '/superadmin',
        },
        // 建模中心
        {
            name: 'cloud-dm',
            entry: '//localhost:3003',
            container: '#subapp-viewport',
            loader,
            activeRule: '/dm',
        },
        // 自定义API后台
        {
            name: 'cloud-data-api',
            entry: '//localhost:3004',
            container: '#subapp-viewport',
            loader,
            activeRule: '/datapi',
        },
        // 猎风大屏
        {
            name: 'cloud-datav',
            entry: '//localhost:3005',
            container: '#subapp-viewport',
            loader,
            activeRule: '/datav',
        },
        // 猎风BI
        {
            name: 'cloud-bi',
            entry: '//localhost:3006',
            container: '#subapp-viewport',
            loader,
            activeRule: '/bi',
        },
        // 猎风 DataApi
        {
            name: 'cloud-datapi',
            entry: '//localhost:3007',
            container: '#subapp-viewport',
            loader,
            activeRule: '/datapi',
        },
		/*
        // 猎风物联网(已经整合到flowapp-ui)
        {
            name: 'cloud-iot',
            entry: '//localhost:3014',
            container: '#subapp-viewport',
            loader,
            activeRule: '/iot',
        },
        // 猎风设备应用管理后台(已经整合到flowapp-ui)
        {
            name: 'cloud-device-app',
            entry: '//localhost:3015',
            container: '#subapp-viewport',
            loader,
            activeRule: '/deviceapp',
        },*/
        // 猎风用户中心管理后台
        {
            name: 'cloud-user-center',
            entry: '//localhost:3016',
            container: '#subapp-viewport',
            loader,
            activeRule: '/usercenter',
        },
		/*
        // 猎风设备-自定义页面管理后台(已经整合到flowapp-ui)
        {
            name: 'cloud-device-page',
            entry: '//localhost:3017',
            container: '#subapp-viewport',
            loader,
            activeRule: '/devicepage',
        },*/
        /*
        // 猎风CMS (已经整合到flowapp-ui)
        {
            name: 'cloud-cms',
            entry: '//localhost:3018',
            container: '#subapp-viewport',
            loader,
            activeRule: '/cms',
        },*/
		/*
        // 猎风客服 (已经整合到flowapp-ui)
        {
            name: 'cloud-customer-service',
            entry: '//localhost:3019',
            container: '#subapp-viewport',
            loader,
            activeRule: '/customerservice',
        },*/
        /*
        // 猎风题库
        {
            name: 'cloud-question-bank',
            entry: '//localhost:3021',
            container: '#subapp-viewport',
            loader,
            activeRule: '/questionbank',
        },*/
        /*
        // 猎风教务
        {
            name: 'cloud-teach',
            entry: '//localhost:3022',
            container: '#subapp-viewport',
            loader,
            activeRule: '/teach',
        },
        // 猎风备课系统
        {
            name: 'cloud-lesson',
            entry: '//localhost:3023',
            container: '#subapp-viewport',
            loader,
            activeRule: '/lesson',
        },*/
        // 猎风预警系统 
        {
            name: 'cloud-bpm',
            entry: '//localhost:3024',
            container: '#subapp-viewport',
            loader,
            activeRule: '/bpm',
        },
        // 猎风知识图谱
        {
            name: 'cloud-graph',
            entry: '//localhost:3025',
            container: '#subapp-viewport',
            loader,
            activeRule: '/graph',
        },
        // 猎风GIS
        {
            name: 'cloud-gis',
            entry: '//localhost:3026',
            container: '#subapp-viewport',
            loader,
            activeRule: '/gis',
        },
        /*
        // 猎风轻应用
        {
            name: 'cloud-liteapp',
            entry: '//localhost:3027',
            container: '#subapp-viewport',
            loader,
            activeRule: '/liteapp',
        },*/
        // 数据资产
        {
            name: 'cloud-data-assets',
            entry: '//localhost:3028',
            container: '#subapp-viewport',
            loader,
            activeRule: '/dataassets',
        },
        // 代理商系统
        {
            name: 'cloud-agent',
            entry: '//localhost:3029',
            container: '#subapp-viewport',
            loader,
            activeRule: '/agent',
        },
        {
            name: 'cloud-flowapp',
            entry: '//localhost:3035',
            container: '#subapp-viewport',
            loader,
            activeRule: '/flowapp',
        },
        // 收银中心-服务台UI
		{
            name: 'cloud-service-desk',
            entry: '//localhost:3040',
            container: '#subapp-viewport',
            loader,
            activeRule: '/servicedesk',
        },
        // 文旅活动后台
        {
            name: 'cloud-cultural',
            entry: '//localhost:3042',
            container: '#subapp-viewport',
            loader,
            activeRule: '/cultural',
        },
        // 商城后台
        {
            name: 'cloud-mall',
            entry: '//localhost:3043',
            container: '#subapp-viewport',
            loader,
            activeRule: '/mall',
        },
        // 猎风活动后台
        {
            name: 'cloud-activity',
            entry: '//localhost:3046',
            container: '#subapp-viewport',
            loader,
            activeRule: '/activity',
        },
    ];

    // 生产环境的而配置
    const prodConfig = [
		/*
        // 猎风OA (已经整合到flowapp-ui)
        {
            // 名字可以随意起
            name: 'cloud-oa',
            entry: '//oaui.liefengkj.com',
            container: '#subapp-viewport',
            loader,
            activeRule: '/oa',
        },*/
        // 超管后台
        {
            name: 'cloud-super-admin',
            entry: '//superadminui.liefengkj.com',
            container: '#subapp-viewport',
            loader,
            activeRule: '/superadmin',
        },
        // 建模中心
        {
            name: 'cloud-dm',
            entry: '//dmui.liefengkj.com',
            container: '#subapp-viewport',
            loader,
            activeRule: '/dm',
        },
		/*
        // 自定义API后台
        {
            name: 'cloud-data-api',
            entry: '//dataapiui.liefengkj.com',
            container: '#subapp-viewport',
            loader,
            activeRule: '/datapi',
        },
        // 猎风大屏
        {
            name: 'cloud-datav',
            entry: '//datavui.liefengkj.com',
            container: '#subapp-viewport',
            loader,
            activeRule: '/datav',
        },*/
        // 猎风BI
        {
            name: 'cloud-bi',
            entry: '//biui.liefengkj.com',
            container: '#subapp-viewport',
            loader,
            activeRule: '/bi',
        },
        /*// 猎风 DataApi
        {
            name: 'cloud-datapi',
            entry: '//dataapiui.liefengkj.com',
            container: '#subapp-viewport',
            loader,
            activeRule: '/datapi',
        },
        // 猎风物联网 (已经整合到flowapp-ui)
        {
            name: 'cloud-iot',
            entry: '//iotui.liefengkj.com',
            container: '#subapp-viewport',
            loader,
            activeRule: '/iot',
        },
        // 猎风设备应用管理后台 (已经整合到flowapp-ui)
        {
            name: 'cloud-device-app',
            entry: '//deviceappui.liefengkj.com',
            container: '#subapp-viewport',
            loader,
            activeRule: '/deviceapp',
        },
        // 猎风用户中心管理后台
        {
            name: 'cloud-user-center',
            entry: '//usercenterui.liefengkj.com',
            container: '#subapp-viewport',
            loader,
            activeRule: '/usercenter',
        },
        // 猎风设备-自定义页面管理后台(已经整合到flowapp-ui)
        {
            name: 'cloud-device-page',
            entry: '//devicepageui.liefengkj.com',
            container: '#subapp-viewport',
            loader,
            activeRule: '/devicepage',
        },*/
        /*
        // 猎风CMS
        {
            name: 'cloud-cms',
            entry: '//cmsui.liefengkj.com',
            container: '#subapp-viewport',
            loader,
            activeRule: '/cms',
        },*/
        /*
        // 猎风客服
        {
            name: 'cloud-customer-service',
            entry: '//csui.liefengkj.com',
            container: '#subapp-viewport',
            loader,
            activeRule: '/customerservice',
        },
        // 猎风题库
        {
            name: 'cloud-question-bank',
            entry: '//questionbankui.liefengkj.com',
            container: '#subapp-viewport',
            loader,
            activeRule: '/questionbank',
        },*/
        /*
        // 猎风教务
        {
            name: 'cloud-teach',
            entry: '//teachui.liefengkj.com',
            container: '#subapp-viewport',
            loader,
            activeRule: '/teach',
        },
        // 猎风备课系统
        {
            name: 'cloud-lesson',
            entry: '//lessonui.liefengkj.com',
            container: '#subapp-viewport',
            loader,
            activeRule: '/lesson',
        },
        // 猎风工作流系统
        {
            name: 'cloud-bpm',
            entry: '//bpmui.liefengkj.com',
            container: '#subapp-viewport',
            loader,
            activeRule: '/bpm',
        },
        // 猎风知识图谱
        {
            name: 'cloud-graph',
            entry: '//graphui.liefengkj.com',
            container: '#subapp-viewport',
            loader,
            activeRule: '/graph',
        },
        // 猎风GIS
        {
            name: 'cloud-gis',
            entry: '//gisui.liefengkj.com',
            container: '#subapp-viewport',
            loader,
            activeRule: '/gis',
        },*/
        /*
        // 猎风轻应用
        {
            name: 'cloud-liteapp',
            entry: '//liteappui.liefengkj.com',
            container: '#subapp-viewport',
            loader,
            activeRule: '/liteapp',
        },
        // 数据资产
        {
            name: 'cloud-data-assets',
            entry: '//dataassetsui.liefengkj.com',
            container: '#subapp-viewport',
            loader,
            activeRule: '/dataassets',
        },*/
        // 代理商系统
        {
            name: 'cloud-agent',
            entry: '//agentui.liefengkj.com',
            container: '#subapp-viewport',
            loader,
            activeRule: '/agent',
        },
		// 后台主入口
        {
            name: 'cloud-flowapp',
            entry: '//flowappui.liefengkj.com',
            container: '#subapp-viewport',
            loader,
            activeRule: '/flowapp',
        },
        /*
		// 收银中心-服务台UI
		{
            name: 'cloud-service-desk',
            entry: '//servicedeskui.liefengkj.com',
            container: '#subapp-viewport',
            loader,
            activeRule: '/servicedesk',
        },*/
        // 文旅活动后台
        {
            name: 'cloud-cultural',
            entry: '//culturalui.liefengkj.com',
            container: '#subapp-viewport',
            loader,
            activeRule: '/cultural',
        },
        // 商城后台
        {
            name: 'cloud-mall',
            entry: '//mallui.liefengkj.com',
            container: '#subapp-viewport',
            loader,
            activeRule: '/mall',
        },
        // 猎风活动后台
        {
            name: 'cloud-activity',
            entry: '//activityui.liefengkj.com',
            container: '#subapp-viewport',
            loader,
            activeRule: '/activity',
        },
    ];

    // 根据请求的端口来判断是否开发环境(正式环境是80端口)
    return window.location.port == '3300' ? devConfig : prodConfig;
};
